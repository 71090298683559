\:root
  .auth-section-theme
    color: var(--white)
    h1
      font-size: 24px
      font-weight: 600
    p
      font-size: 16px
      letter-spacing: normal
    a,
    a.mat-mdc-button
      color: #FFFFFF
      cursor: pointer
      font-weight: 500
      &:not(.mat-mdc-button)
        text-decoration: underline

  section
    a:-webkit-any-link
      color: var(--primary-blue)
      text-decoration: none

    h2, p
      color: var(--gunmetal)

    h2
      font-size: 22px
      &:first-child
        margin-top: 0

    h3
      font-size: 20px

  a:focus-visible
    outline: 0

  .fs-11,
  p.fs-11
    font-size: 11px

  .fs-12,
  p.fs-12
    font-size: 12px

  .fs-13,
  h1.fs-13,
  p.fs-13
    font-size: 13px

  .fs-14,
  h1.fs-14,
  h2.fs-14,
  h3.fs-14,
  h4.fs-14,
  p.fs-14
    font-size: 14px

  .fs-16,
  h1.fs-16,
  h2.fs-16,
  h3.fs-16,
  p.fs-16
    font-size: 16px

  .fs-18,
  h1.fs-18,
  h2.fs-18,
  h3.fs-18,
  p.fs-18
    font-size: 18px

  .fs-22,
  h1.fs-22
    font-size: 22px

  .fs-24
    font-size: 24px

  .fs-28
    font-size: 28px

  .fs-32
    font-size: 32px

  .fs-36,
  h1.fs-36
    font-size: 36px

  .fw-500
    font-weight: 500 !important
