\:root

  .mat-mdc-form-field-error-wrapper,
  .mat-mdc-form-field-hint-wrapper
    padding-left: 5px
    padding-right: 0

  // AUTH SECTIONS
  .auth-section-theme
    .mdc-text-field__input
      &:-webkit-autofill
        -webkit-background-clip: text
        -webkit-text-fill-color: var(--white)
    .mat-mdc-text-field-wrapper:not(.mdc-text-field--disabled)
      background-color: var(--white-015)
      color: var(--white)
      border-radius: 8px
    .mdc-text-field--filled:not(.mdc-text-field--invalid)
      --mdc-filled-text-field-active-indicator-height: 0px
    .mat-mdc-form-field
      .mat-mdc-form-field-subscript-wrapper
        .mat-mdc-form-field-error-wrapper
          .mat-mdc-form-field-error
            color: var(--red-status_alt)
    .mdc-text-field--filled
      &:not(.mdc-text-field--disabled)
        &.mdc-text-field--focused
          .mdc-floating-label,
          .mdc-floating-label--float-above
            color: var(--white)

// LOGGED IN SECTIONS
:not(.auth-section-theme)
  .mat-mdc-form-field
    .mdc-text-field--outlined
      background: var(--white)
      border-radius: 8px
      .mdc-text-field__input
        &:-webkit-autofill
          -webkit-background-clip: text
          color: var(--gunmetal)
      .mdc-text-field__input::placeholder,
      .mat-icon
        color: var(--cool-grey)
      .mat-icon[matPrefix]
        margin-left: 10px
      .iti--allow-dropdown input.iti__tel-input, .iti--allow-dropdown input.iti__tel-input[type=text], .iti--allow-dropdown input.iti__tel-input[type=tel], .iti--show-selected-dial-code input.iti__tel-input, .iti--show-selected-dial-code input.iti__tel-input[type=text], .iti--show-selected-dial-code input.iti__tel-input[type=tel]
        padding-left: 48px
      .mdc-notched-outline__leading
        border-radius: 8px 0 0 8px
      .mdc-notched-outline__trailing
        border-radius: 0 8px 8px 0
      .mdc-notched-outline__leading,
      .mdc-notched-outline__notch,
      .mdc-notched-outline__trailing
        border-color: var(--silver)
        border-width: 1px
      &:not(.mdc-text-field--disabled, .mdc-text-field--focused, .mdc-text-field--invalid):hover .mdc-notched-outline
        .mdc-notched-outline__leading,
        .mdc-notched-outline__notch,
        .mdc-notched-outline__trailing
          border-color: var(--cool-grey)
      &.mdc-text-field--focused
        .mat-mdc-form-field-icon-prefix
          .mat-icon
            color: var(--primary-blue)
      // Disabled
      &.mdc-text-field--disabled
        .mdc-text-field__input
          color: var(--cool-grey)
        .mat-icon
          color: var(--silver)
        .mdc-notched-outline__leading,
        .mdc-notched-outline__notch,
        .mdc-notched-outline__trailing
          border-color: var(--light-silver)
      // Invalid
      &.mdc-text-field--invalid
        .mdc-text-field__input
          color: var(--red-status)
        .mdc-notched-outline__leading,
        .mdc-notched-outline__notch,
        .mdc-notched-outline__trailing
          border-color: var(--red-status)
    .mat-mdc-form-field-error
      color: var(--red-status)
  &.feedback-disabled // This class remove the area below the inputs (it's used for error and hint messages)
    .mat-mdc-form-field-subscript-wrapper
      display: none
  // Small Inputs
  &[size='small']
    .mdc-text-field--outlined
      .mdc-notched-outline__leading,
      .mdc-notched-outline__notch,
      .mdc-notched-outline__trailing
        height: 36px
      .mat-mdc-form-field-infix
        min-height: 36px
        padding: 6px 0
        line-height: normal
      .mat-mdc-icon-button.mat-mdc-button-base,
      .mat-mdc-icon-button .mat-mdc-button-touch-target
        height: 36px
        width: 36px
      .mat-mdc-icon-button.mat-mdc-button-base
        padding: 6px
      .mat-mdc-form-field-flex
        .mat-mdc-floating-label
          top: 18px
      .mdc-notched-outline--upgraded
        .mdc-floating-label--float-above
          transform: translateY( -27.5px) scale(var(--mat-mdc-form-field-floating-label-scale, .75))
  // Large Inputs
  &[size='large']
    .mdc-text-field--outlined
      .mdc-notched-outline__leading,
      .mdc-notched-outline__notch,
      .mdc-notched-outline__trailing
        height: 76px
      .mat-mdc-form-field-infix
        min-height: 76px
        padding: 6px 0
      .mat-mdc-icon-button.mat-mdc-button-base,
      .mat-mdc-icon-button .mat-mdc-button-touch-target
        height: 76px
        width: 76px
      .mat-mdc-icon-button.mat-mdc-button-base
        padding: 6px
      .mat-mdc-form-field-input-control,
      .mat-mdc-select-trigger
        min-height: 64px
      .mat-mdc-form-field-flex
        --mat-mdc-form-field-label-offset-x: -6px
        .mat-mdc-floating-label:not(.mdc-floating-label--float-above)
          top: 50%

\:root
  // CHECKBOXS
  .mdc-checkbox
    .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background
      border-color: var(--cool-grey)
    .mdc-checkbox__native-control:enabled:focus:focus:not(:checked):not(:indeterminate)~.mdc-checkbox__background
      border-color: var(--gunmetal)

  // ERRORS IN MULTIPLES LINES (MAT_FORM_FIELD_DEFAULT_OPTIONS subscriptSizing: 'dynamic')
  :not(.feedback-ok)
    .mat-mdc-form-field-subscript-dynamic-size
      min-height: 20px
      margin-bottom: 10px
      .mat-mdc-form-field-error
        line-height: 1.25
        margin-bottom: 2px

  // CUSTOM ERRORS AND FEEDBACK
  .feedback-ok
    display: none
  @media (min-width: $layout-breakpoint-large)
    .mat-mdc-form-field
      .feedback-ok
        display: block
        position: absolute
        left: calc(100% + 23px)
        top: 0
        bottom: 0
        mat-icon
          color: var(--green-status)!important
          position: absolute
          top: 0
          bottom: 0
          margin-top: auto
          margin-bottom: auto
      &.mat-mdc-form-field-has-icon-suffix
        .feedback-ok
          left: calc(100% + 59px)
    .custom-validations // We use this class to style the errors feedback. Ex:used in the registration form
      .mat-mdc-form-field
        position: relative
        &.mb-3
          margin-bottom: 5px!important
        .mat-mdc-form-field-subscript-wrapper
          position: absolute
          left: calc(100% + 38px)
          max-width: 36vw
          top: 0
          bottom: 0
          .mat-mdc-form-field-error-wrapper
            background: var(--red-status)
            border-radius: 8px
            padding: 10px 12px
            margin-top: auto
            margin-bottom: auto
            top: 0
            bottom: 0
            height: fit-content
            width: fit-content
            &:before
              font-family: 'Material Icons'
              font-size: 24px
              color: var(--red-status)
              content: 'error'
              position: absolute
              top: calc(50% - 12px)
              left: -30px
            .mat-mdc-form-field-error
              color: var(--white)
              letter-spacing: 0.2px
              font-size: 12px
              line-height: 120%
              width: fit-content

  // SELECT FOR MAPS
  #layerSelector
    background: transparent
    box-shadow: none
    margin-right: 10px
    .mdc-text-field--outlined
      width: 204px
      color: var(--white)
      background: var(--primary-green-070)
      backdrop-filter: blur(1px)
      border-radius: 8px
      transition: background-color 0.25s ease-in-out
      &:hover,
      &:active,
      &:focus,
      &.mdc-text-field--focused
        background: var(--primary-green)
      .mat-mdc-select,
      .mat-icon
        color: var(--white)
      .mat-mdc-select
        font-size: 14px
      .mat-mdc-select-arrow
        display: none
      .mat-mdc-form-field-infix
        min-height: 24px
        padding-top: 11px
        padding-bottom: 11px
      .mdc-notched-outline__leading,
      .mdc-notched-outline__notch,
      .mdc-notched-outline__trailing
        border: none
  #layerCombobox-panel
    &.mat-mdc-select-panel
      border-radius: 8px
      background-color: var(--white-080)
      backdrop-filter: blur(2px)
      .mat-mdc-option
        min-height: 44px
        .mdc-list-item__primary-text
          font-size: 14px

//CUSTOM INPUT TYPE FILE
.file-drop-zone
  display: flex
  flex-direction: row
  align-content: center
  justify-content: center
  border: dashed 2px
  border-radius: 8px
  padding: 0.5em
  margin: 0.2em 0 0 0
  text-align: center
  transition: border-color 0.3s

.hovering
  border: solid 2px var(--primary-blue)

.file-selected
  background-color: var(--light-grey)

.error
  border: solid 2px var(--red-status)
  background-color: var(--red-status-010)

.file-label
  margin-bottom: 0.5em
  font-weight: bold

.file-input
  display: none
  width: 100%
  height: 100%

  // TRACE CUSTOM FILE INPUT 
.dropzone
  height: 131px
  display: flex
  flex-direction: column
  align-content: center
  justify-content: center
  border: dashed 1px var(--cool-grey)
  border-radius: 8px
  padding: 0.5em
  margin: 0.2em 0 0 0
  text-align: center
  transition: border-color 0.3s
  .mat-icon
    &.hovering
      border: solid 1px var(--primary-blue)
    &.error
      border: solid 1px var(--red-status)
