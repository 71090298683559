\:root

  .mat-mdc-standard-chip
    &[size='xsmall']
      height: 15px
      .mdc-evolution-chip__text-label
        font-size: 11px
      .mdc-evolution-chip__action--primary
        padding-left: 6px
        padding-right: 6px
    &[size='small']
      height: 22px
      .mdc-evolution-chip__icon--primary
        font-size: 16px
        width: 16px
        height: 16px
      .mdc-evolution-chip__text-label
        font-size: 12px
    &:not(.mdc-evolution-chip--disabled)
      .mdc-evolution-chip__text-label
        color: var(--cool-grey)
      &.info-status
        background: var(--pale-grey)
        .mdc-evolution-chip__text-label
          color: var(--primary-blue)
      &.info-status_alt
        background: var(--primary-blue)
        .mdc-evolution-chip__text-label
          color: var(--white)
      &.green-status
        background: var(--green-status-010)
        .mdc-evolution-chip__icon--primary
          color: var(--green-status)
      &.red-status
        background: var(--red-status-010)
        .mdc-evolution-chip__icon--primary
          color: var(--red-status)
      &.yellow-status
        background: var(--yellow-status-010)
        .mdc-evolution-chip__icon--primary
          color: var(--yellow-status)
      &.red-trace
        background: var(--red-status)
        .mdc-evolution-chip__text-label
          color: var(--white)
        .mdc-evolution-chip__icon--primary
          color: var(--white)
      &.yellow-trace
        background: var(--yellow-status)
        .mdc-evolution-chip__text-label
          color: var(--white)
        .mdc-evolution-chip__icon--primary
          color: var(--white)
      &.green-trace
        background: var(--green-status)
        .mdc-evolution-chip__text-label
          color: var(--white)
        .mdc-evolution-chip__icon--primary
          color: var(--white)
    &.mdc-evolution-chip--with-avatar.mdc-evolution-chip--with-primary-graphic
      .mdc-evolution-chip__graphic
        padding-right: 0
