\:root

  // AUTH SECTION
  .auth-section-theme
    .mat-mdc-unelevated-button
      &.mat-primary
        font-size: 16px
        font-weight: bold
        letter-spacing: 0.5px

  // OTHERS SECTIONS
  .mat-mdc-button.flex-row-reverse
    .mat-icon
      margin-right: 0
      margin-left: 4px

  .mat-mdc-icon-button.mat-mdc-button-base
    &[disabled]
      --mdc-icon-button-disabled-icon-color: var(--silver)
    &[size='small']
      width: 30px
      height: 30px
      padding: 2px
      .mat-mdc-button-touch-target
        width: 30px
        height: 30px
      .mat-icon
        font-size: 18px
        width: 18px
        height: 18px
    &[size='medium']
      width: 32px
      height: 32px
      padding: 4px
      .mat-mdc-button-touch-target
        width: 32px
        height: 32px

  .mat-mdc-button.mat-mdc-button-base,
  .mat-mdc-raised-button.mat-mdc-button-base,
  .mat-mdc-unelevated-button.mat-mdc-button-base,
  .mat-mdc-outlined-button.mat-mdc-button-base
    min-height: 48px
    border-radius: 8px
    letter-spacing: 0px
    font-weight: 400
    &.light-silver-btn
      color: var(--primary-blue)
      background-color: var(--light-silver)
      align-self: center
      font-size: 11px
      font-weight: 500
    &[size]
      min-height: auto
    // Extra Small Buttons
    &[size='xsmall']
      height: 32px
      font-size: 13px
      letter-spacing: 0.5px
      .mat-icon
        font-size: 22px
        width: 22px
        height: 22px
      &.cool-grey-btn
        color: var(--primary-blue)
        background-color: var(--pale-grey)
        width: 170px
        align-self: center
        font-size: 13px
        &[disabled]
          color: var(--white)
    // Small Buttons
    &[size='small']
      height: 36px
      font-size: 14px
      .mat-icon
        font-size: 22px
        width: 22px
        height: 22px

.x-button
  display: flex
  align-items: center
  font-size: 12px
  background-color: transparent
  border: none
  border-radius: 2px
  margin: 0 3px
  &:hover
    background-color: var(--red-status)
    cursor: pointer

.light-silver-icon-btn
  display: flex
  align-items: center
  justify-content: center
  color: var(--primary-blue)
  background-color: var(--light-silver)
  border: none
  border-radius: 4px
  font-size: 21px
  height: 24px
  width: 24px
  font-weight: 500
  margin: 0 3px
  .mat-icon
    height: 16px
    width: 16px
    font-size: 16px
  &:hover
    background-color: var(--silver)
    cursor: pointer
